import React, { SetStateAction, useCallback } from 'react'
import cx from 'classnames'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { IProductImage } from 'models'
import { imagesDeclension } from 'helpers'
import { useMessage } from 'hooks/useMessage'
import { Icon, Text } from 'components/UI'
import $api from 'components/http/axios'
import s from './dropzone.module.scss'

interface IDropzoneProps {
  sellerId: string | null
  images: IProductImage[]
  setImages: React.Dispatch<SetStateAction<IProductImage[]>>
}

const Dropzone = ({ sellerId, images, setImages }: IDropzoneProps) => {
  const onDrop: any = useCallback(
    async (acceptedFiles: File[]) => {
      const loadingToast = useMessage(
        '',
        'loading',
        `Загрузка ${acceptedFiles.length > 1 ? 'изображений' : 'изображения'}`
      )

      const showUpdateMessage = (message: string, type: 'error' | 'success' = 'error') =>
        toast.update(loadingToast, {
          autoClose: 3000,
          type,
          isLoading: false,
          render: message
        })

      try {
        if (acceptedFiles.length > 1 && images?.length + acceptedFiles.length > 10) {
          return showUpdateMessage(
            `Превышено кол-во фотографий. Вы можете загрузить ${10 - images.length} ${imagesDeclension(
              10 - images.length
            )} изображений`
          )
        }
        if (acceptedFiles.length === 1 && images?.length > 9) {
          return showUpdateMessage('Превышено кол-во фотографий')
        }

        const newFiles: File[] = []
        const formData = new FormData()

        acceptedFiles.forEach((file: File) => {
          if (file.size > 3 * 1024 * 1024) {
            return showUpdateMessage(`Превышен размер фотографии ${file.name}`)
          }

          newFiles.push(file)
          formData.append('images', file)
        })

        if (newFiles.length) {
          const response = await $api.post(`/api/seller/${sellerId}/products/images`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })

          if (response.status === 201) {
            setImages((prevState: IProductImage[]) => [
              ...prevState,
              ...response.data.map((v: IProductImage) => ({
                ...v,
                order: prevState.length + (v?.order ?? 0)
              }))
            ])

            showUpdateMessage('Изображения успешно загружены', 'success')
          }
        }
      } catch (e) {
        console.error(e)
        showUpdateMessage('Ошибка при загрузке изображений')
      }
    },
    [images]
  )

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg']
    }
  })

  return (
    <div
      className={cx(s.dropzone, {
        [s['dropzone--accept']]: isDragAccept,
        [s['dropzone--reject']]: isDragReject,
        [s.disabled]: images?.length >= 10
      })}
      {...getRootProps()}
    >
      <div
        className={cx(s.dropzone__icon, {
          [s['dropzone__icon--accept']]: isDragAccept,
          [s['dropzone__icon--reject']]: isDragReject
        })}
      >
        <Icon name='image-square' size='xxl' color='dark-grey' />
      </div>
      <div
        className={cx(s.dropzone__title, {
          [s['dropzone__title--accept']]: isDragAccept,
          [s['dropzone__title--reject']]: isDragReject
        })}
      >
        {isDragAccept ? (
          <Text size='md'>Все хорошо! Опустите файлы здесь.</Text>
        ) : isDragReject ? (
          <Text size='md'>Неподходящий размер или формат файла</Text>
        ) : (
          <>
            <input style={{ display: 'none' }} {...getInputProps()} />
            <Text as='p' size='md' color='blue' cursor='pointer'>
              Выберите
              <Text color='middle-grey' cursor='auto'>
                или перетащите файлы в эту область для загрузки
              </Text>
            </Text>
          </>
        )}
      </div>
      <Text as='p' className={s.dropzone__description} color='extra-grey'>
        Формат: jpeg, png, jpg. Общий размер файлов не больше 10 Мб.
      </Text>
    </div>
  )
}

export default Dropzone
