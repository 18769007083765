import { useMutation } from '@tanstack/react-query'
import $api from 'components/http/axios'
import { useMessage } from 'hooks'
import { TRestErrorType } from 'models'

interface IFormData {
  shopId: string
  withdrawalId: string
}
interface IUseWithdrawMoney {
  onSuccessFunc?: VoidFunction
}

export const useWithdrawMoney = ({ onSuccessFunc }: IUseWithdrawMoney) => {
  const { mutate } = useMutation({
    mutationFn: async (formData: IFormData) => {
      await $api.post(`/api/affiliate/withdrawals/confirm`, formData)
    },
    onSuccess: () => {
      onSuccessFunc?.()
    },
    onError: ({ response }: TRestErrorType) =>
      useMessage(response?.data?.message || 'Ошибка при выводе средств!', 'error')
  })

  return { mutate }
}
