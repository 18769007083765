import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
  activeTooltipId: string | null
}

const initialState: UiState = {
  activeTooltipId: null
}

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    setActiveTooltipId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      activeTooltipId: action.payload
    })
  }
})

export default uiSlice.reducer

export const { setActiveTooltipId } = uiSlice.actions
