import { useMutation } from '@tanstack/react-query'
import $api from 'components/http/axios'
import { useMessage } from 'hooks'
import { TRestErrorType } from 'models'

interface IFormData {
  productId: string
  discountPercentage: string
  endDate: string
  shopId?: string
}
interface IUseCreatePartnerProgram {
  closeModal: VoidFunction
}

export const useCreatePartnerProgram = ({ closeModal }: IUseCreatePartnerProgram) => {
  const { mutate } = useMutation({
    mutationFn: async (formData: IFormData) => {
      await $api.post(`/api/affiliate/programs`, formData)
    },
    onSuccess: () => {
      closeModal()
    },
    onError: ({ response }: TRestErrorType) =>
      useMessage(response?.data?.message || 'Ошибка создания партнерской программы!', 'error')
  })

  return { mutate }
}
