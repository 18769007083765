import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { useAppSelector } from 'redux/hook'
import { TOrder, EOrderStatus } from 'models'
import { setSpaceBetweenCharacters } from 'helpers'
import { useQueryParams } from 'hooks'
import { useTimeHook } from 'components/Hooks/useTime'
import {
  Drawer,
  DropDownList,
  Icon,
  PageEmpty,
  PageHeader,
  Pagination,
  Search,
  Table,
  TableSkeleton
} from 'components/UI'
import { OrderDateFilter, BaseFilter } from 'components/filters'
import $api from 'components/http/axios'
import OrderInfoDrawer from 'components/orders/OrderInfoDrawer'
import OrderStatusLabel from 'components/orders/OrderStatusLabel'
import s from './orders-page.module.scss'

type TSort = 'price_asc' | 'price_desc' | 'date_asc' | 'date_desc'

interface IPeriodValue {
  fromDate: string
  toDate: string
}

const OrdersPage = () => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<IPeriodValue | null>(null)
  const [statusType, setStatusType] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState | undefined>(undefined)
  const { getQueryParams, setQueryParams, deleteQueryParams, searchParams } = useQueryParams()
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const {
    data: orders,
    isLoading,
    isError
  } = useQuery<TOrder[]>({
    queryKey: ['getOrders', activeShop?._id, ...searchParams.entries(), searchValue, periodValue, statusType],
    queryFn: async () => {
      const { status, data } = await $api.get(
        `/api/seller/${activeShop?._id}/orders?${searchParams.toString()}${searchValue ? `&orderNumber=${searchValue}` : ''}${
          periodValue ? `&fromDate=${periodValue.fromDate}&toDate=${periodValue.toDate}` : ''
        }${statusType ? `&status=${statusType}` : ''}`
      )

      if (status !== 200) throw new Error('Ошибка получения заказа!')

      setTotalCount(data?.totalCount || 0)

      return data.orders
    }
  })

  const setFirstPage = () => setQueryParams('page', '1')

  const onPaginationChange = (value: number) => setQueryParams('page', value.toString())

  const onSorting = (value: SortingState) => setSorting(value)

  const onLimitChange = (value: number) => {
    setQueryParams('limit', String(value))
    setFirstPage()
  }

  const onStatusChange = (value: string) => {
    setStatusType(value)
    setFirstPage()
  }

  const onDateChange = (startDate: string | null, endDate?: string) => {
    setPeriodValue(startDate && endDate ? { fromDate: startDate, toDate: endDate } : null)
    setFirstPage()
  }

  useEffect(() => {
    const sortQueryParams = getQueryParams('sort')

    if (sortQueryParams) {
      if (sortQueryParams === 'price_asc') setSorting([{ id: 'totalPrice', desc: false }])
      if (sortQueryParams === 'price_desc') setSorting([{ id: 'totalPrice', desc: true }])
      if (sortQueryParams === 'date_asc') setSorting([{ id: 'createdAt', desc: false }])
      if (sortQueryParams === 'date_desc') setSorting([{ id: 'createdAt', desc: true }])
    }
  }, [])

  useEffect(() => {
    if (sorting) {
      const sortingKeys = {
        createdAt: ['date_asc', 'date_desc'],
        totalPrice: ['price_asc', 'price_desc']
      } as Record<string, TSort[]>

      const correctKey = sortingKeys?.[sorting?.[0]?.id]?.[sorting?.[0]?.desc ? 1 : 0]

      if (correctKey) {
        setQueryParams('sort', correctKey)
      } else {
        deleteQueryParams('sort')
      }

      setFirstPage()
    }
  }, [sorting])

  const columns: ColumnDef<TOrder>[] = [
    {
      accessorKey: 'index',
      header: '№',
      size: 65,
      enableSorting: false
    },
    {
      accessorKey: 'orderNumber',
      header: 'Номер заказа',
      minSize: 180,
      enableSorting: false
    },
    {
      accessorKey: 'createdAt',
      header: 'Дата заказа',
      minSize: 130,
      cell: (info: CellContext<TOrder, unknown>) => useTimeHook(info.getValue() as Date)?.minDate || ''
    },
    {
      accessorKey: 'totalPrice',
      header: 'Сумма, ₽',
      minSize: 100,
      cell: (info: CellContext<TOrder, unknown>) => setSpaceBetweenCharacters(info.getValue() as number)
    },
    {
      accessorKey: 'status',
      header: 'Статус заказа',
      size: 200,
      minSize: 100,
      enableSorting: false,
      cell: (info: CellContext<TOrder, unknown>) => <OrderStatusLabel status={info.getValue() as EOrderStatus} />
    }
  ]

  if (isError) return <PageEmpty title='Не удалось получить данные. Попробуйте еще раз' />

  return (
    <div className={s.orders}>
      <PageHeader title='Заказы'>
        <div className={s.orders__header}>
          <div className={s.filters}>
            <BaseFilter
              icon={<Icon name='status' color='dark-grey' />}
              label='Статус заказа'
              filterData={['Оплачено']}
              onFilterChange={onStatusChange}
              disabled={!orders?.length && !statusType}
            />
            <OrderDateFilter onFilterChange={onDateChange} disabled={!orders?.length && !periodValue} />
          </div>
          <div className={s.search}>
            <Search
              onChange={setSearchValue}
              placeholder='Поиск по номеру заказа'
              disabled={!orders?.length && !searchValue}
            />
          </div>
        </div>
      </PageHeader>
      <div className={s.orders__content}>
        {isLoading ? (
          <TableSkeleton />
        ) : orders?.length ? (
          <Table<TOrder>
            defaultData={orders || []}
            defaultColumns={columns}
            onSorting={onSorting}
            onRowClick={(data) => setQueryParams('order', data._id)}
            sorting={sorting || []}
            resize
            fluid
          />
        ) : (
          <PageEmpty title='Нет заказов' fullHeight={false} />
        )}
      </div>
      {Boolean(orders?.length) && (
        <div className={s.orders__footer}>
          <Pagination
            totalCount={totalCount}
            pageIndex={parseInt(getQueryParams('page') ?? '1', 10)}
            onPage={parseInt(getQueryParams('limit') ?? '20', 10)}
            onChange={onPaginationChange}
          />
          <div className={s.limit}>
            <DropDownList<number>
              value={Number(getQueryParams('limit') ?? 20)}
              options={[10, 20, 30, 40, 50]}
              onSelect={onLimitChange}
              label='Количество заказов'
            />
          </div>
        </div>
      )}

      <Drawer show={Boolean(getQueryParams('order'))} onClose={() => deleteQueryParams('order')} width='760px'>
        <OrderInfoDrawer />
      </Drawer>
    </div>
  )
}

export default OrdersPage
