import React, { useEffect, useRef } from 'react'
import { Input, Text } from 'components/UI'
import { useDebounce, useQueryParams, useMessage } from 'hooks'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'redux/hook'
import $api from 'components/http/axios'
import { handleCategorySearch } from 'hooks/handleCategorySearch'
import { UseFormRegister } from 'react-hook-form'
import { IProduct } from 'models'
import { Category } from 'components/UI/selectCategory/types'
import s from './style.module.scss'

interface IInputSearchFilterProps {
  onChange: (value: any) => void
  placeholder?: string
  title: string
  itemLabel?: string
  selectedValue?: string
  name: string
  register: UseFormRegister<any>
  errors: any
}

type TCategory = {
  v: Category[]
  text: string
}

const InputSearchFilter = ({
  onChange,
  placeholder = 'Поиск',
  title,
  itemLabel,
  selectedValue,
  name,
  ...props
}: IInputSearchFilterProps) => {
  const [value, setValue] = React.useState<string>('')
  const searchInputRef = useRef(null)
  const debouncedValue = useDebounce<string>(value, 300)
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { getQueryParams, setQueryParams, searchParams, deleteQueryParams } = useQueryParams()

  const { data: products } = useQuery({
    queryKey: ['getFilterProducts', activeShop?._id, ...searchParams.entries()],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/seller/${activeShop?._id}/products?text=${getQueryParams('product')}`
      )
      if (status !== 200) return useMessage('Ошибка получения товаров!', 'error')
      return data?.products
    },
    retry: 2,
    enabled: Boolean(getQueryParams('product'))
  })

  const { categories } = handleCategorySearch(getQueryParams('category') || '')

  const onClearInput = () => {
    setValue('')
  }

  useEffect(() => {
    if (selectedValue?.trim() !== debouncedValue.trim()) {
      setQueryParams(title === 'Товар' ? 'product' : 'category', debouncedValue.trim() || '')
    }
  }, [debouncedValue])

  const chooseProduct = (e: any) => {
    onChange(e)
    setValue(e?.productName)
    deleteQueryParams('product')
    deleteQueryParams('category')
  }

  return (
    <div style={{ flexDirection: 'column' }} className={s.search}>
      <Input
        ref={searchInputRef}
        name={name}
        type='search'
        view='search'
        placeholder={placeholder}
        rules={{ required: { value: true, message: 'Не заполнено поле' } }}
        value={value}
        classNameInputWrapper={s['input-search']}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
        }}
        label={itemLabel}
        onClear={onClearInput}
        fluid
        autoFocus
        {...props}
      />
      <div className={s['list-items']}>
        {products &&
          products.map((e: IProduct) => (
            <div
              key={Math.random()}
              onClick={() => chooseProduct(e)}
              style={{ width: '335px' }}
              className={s['list-item']}
            >
              <img className={s['list-item__image']} src={e.images[0]?.link} alt={e.productName} />
              <Text as='p' family='object-sans' className={s['list-item__title']} align='start'>
                {e.productName}
              </Text>
            </div>
          ))}
        {categories &&
          categories.map((e: TCategory) => (
            <div
              key={Math.random()}
              onClick={() => chooseProduct({ ...e.v[0], fullName: e.text })}
              style={{ width: '335px' }}
              className={s['list-item']}
            >
              <Text as='p' family='object-sans' className={s['list-item__title']} align='start'>
                {e.text}
              </Text>
            </div>
          ))}
      </div>
    </div>
  )
}

export default InputSearchFilter
