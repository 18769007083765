import React from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { IUser } from 'redux/types'
import { Table } from 'components/UI'

const columns: ColumnDef<IPartner>[] = [
  {
    accessorKey: 'index',
    header: '№',
    size: 44,
    enableSorting: false
  },
  {
    accessorKey: 'user.name',
    header: 'Партнеры',
    size: 320,
    minSize: 320,
    cell: (info: CellContext<IPartner, unknown>) => info.getValue() ?? 'Имя не указано'
  },
  {
    accessorKey: 'sales',
    header: 'Продажи',
    size: 95,
    minSize: 95
  },
  {
    accessorKey: 'earnings',
    header: 'Баллы',
    size: 95,
    minSize: 95
  }
]

interface IPartner {
  _id: string
  user: IUser
  sales: number
  earnings: number
}

interface IPartnerTableContent {
  partners: IPartner[]
}

const TableContent = ({ partners }: IPartnerTableContent) => (
  <Table defaultData={partners} defaultColumns={columns} resize fluid />
)

export default TableContent
