import React from 'react'
import { ITableRowFeatures } from 'models'
import { Row } from '@tanstack/react-table'
import { Icon, Text, Tooltip } from 'components/UI'
import s from './elements.module.scss'

interface IFeaturesListProps<T> {
  row: Row<T>
  features: ITableRowFeatures<T>[]
}

const FeaturesList = <T,>({ row, features }: IFeaturesListProps<T>) => (
  <Tooltip iconSize='xs' id={row?.id || String(Math.random())} closeAfterClickOnContent>
    <div className={s['features-list']}>
      {features.map((feature: ITableRowFeatures<T>) => (
        <div
          key={feature.text}
          className={s['features-list__item']}
          onClick={() => (feature?.onClick ? feature.onClick(row.original) : {})}
        >
          <Icon className={s.icon} name={feature.icon} size='md' />
          <Text className={s.text} size='sm'>
            {feature.text}
          </Text>
        </div>
      ))}
    </div>
  </Tooltip>
)

export default FeaturesList
