import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Text } from 'components/UI'
import { IProduct } from 'models'
import { InputSearchFilter } from 'components/filters/searchFilter'
import { stringtoDateString } from 'utils/stringtoDateString'
import { useAppSelector } from 'redux/hook'
import s from './partner-modal.module.scss'
import { useCreatePartnerProgram } from '../hooks'

interface IAddPartnerProgrammForm {
  product: IProduct
  discountPercentage: string
  endDate: string
}

interface IPartnerModalProps {
  onClose: () => void
  refetchTable?: () => void
}
const PartnerModal = ({ onClose, refetchTable }: IPartnerModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IAddPartnerProgrammForm>()

  const closeModal = () => {
    refetchTable?.()
    onClose()
  }

  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { mutate } = useCreatePartnerProgram({ closeModal })

  const onSubmit = ({ discountPercentage, endDate, product }: IAddPartnerProgrammForm) => {
    mutate({
      discountPercentage,
      endDate: stringtoDateString(endDate),
      productId: product._id,
      shopId: activeShop?._id
    })
  }

  return (
    <form className={s['partner-modal']} onSubmit={handleSubmit(onSubmit)}>
      <Text className={s['partner-modal__title']} as='p' size='lg' family='object-sans' weight='bold'>
        Создание партнерской программы
      </Text>
      <InputSearchFilter
        selectedValue={watch('product')?.productName}
        title='Товар'
        onChange={(val: IProduct) => setValue('product', val)}
        itemLabel='Выбор товара'
        name='product'
        errors={errors}
        register={register}
      />
      <div className={s['partner-modal__rowInputs']}>
        <Input
          name='discountPercentage'
          type='number'
          rules={{
            required: { value: true, message: 'Не заполнено поле' },
            pattern: { value: /^[1-9]\d?$/, message: 'Некорректный формат' },
            min: { value: 1, message: 'Минимальное значение 1%' },
            max: { value: 99, message: 'Максимальное значение 99%' }
          }}
          register={register}
          errors={errors}
          label='Размер скидки, %'
          autoFocus
          fluid
        />
        <Input
          name='endDate'
          type='date'
          rules={{ required: { value: true, message: 'Не заполнено поле' } }}
          register={register}
          errors={errors}
          label='Период действия'
          autoFocus
          fluid
        />
      </div>
      <div className={s['partner-modal__rowButtons']}>
        <Button onClick={onClose} view='link'>
          Отмена
        </Button>
        <Button type='submit'>Создать</Button>
      </div>
    </form>
  )
}

export default PartnerModal
