import { combineReducers, configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './auth/authSlice'
import sellerReducer from './seller/sellerSlice'
import uiReducer from './ui/uiSlice'
import { authApi } from './auth/authApi'
import { sellerApi } from './seller/sellerApi'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui']
}

const reducers = {
  auth: authReducer,
  seller: sellerReducer,
  ui: uiReducer
}

const combinedReducer = combineReducers<typeof reducers>(reducers)
const persistedReducer = persistReducer(persistConfig, combinedReducer)

export const makeStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      store: persistedReducer,
      [authApi.reducerPath]: authApi.reducer,
      [sellerApi.reducerPath]: sellerApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(authApi.middleware, sellerApi.middleware),
    ...options,
    devTools: true
  })

export const store = makeStore()
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
