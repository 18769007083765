import { IProductImage } from './products'

export type TOrderItemProductImage = Required<IProductImage>

export type TOrderItemProduct = {
  images: TOrderItemProductImage[]
  productName: string
  _id: string
}

type TOrderDeliveryType = {
  label: 'Самовывоз из магазина' | 'Доставка курьером'
  _id: string
}

export type TOrderItem = {
  price: number
  product: TOrderItemProduct
  quantity: number
  totalPrice: number
  SKU: string
  _id: string
}

export enum EOrderPaymentMethodLabels {
  online = 'Картой онлайн',
  cod = 'При получении'
}

export type TOrderPaymentMethod = {
  _id: string
  label: EOrderPaymentMethodLabels
}

export type TOrderRecipientData = {
  email: string
  firstName: string
  lastName: string
  phone: string
}

export enum EOrderStatus {
  CREATED = 'Создан',
  PAID = 'Оплачено',
  CANCELLED_BY_CUSTOMER = 'Отменено покупателем',
  CANCELLED_BY_SELLER = 'Отменено продавцом',
  BEING_ASSEMBLED = 'В сборке',
  DELIVERED = 'Доставлено'
}

export enum PartnersStatus {
  WAITING_FOR_PAID = 'Ожидает оплаты',
  DONE = 'Завершено'
}

export type TOrder = {
  createdAt: Date
  deliveryAddress: string
  deliveryType: string
  index: number
  orderGroup: string
  orderItems: TOrderItem[]
  orderNumber: number
  paymentMethod: string
  recipientData: string
  shop: string
  totalPrice: number
  updatedAt: Date
  user: string
  __v: number
  _id: string
}

export type TOrderCard = {
  createdAt: Date
  deliveryAddress: string
  deliveryType: TOrderDeliveryType
  deliveryPrice?: number
  orderGroup: string
  orderItems: TOrderItem[]
  orderNumber: number
  paymentMethod: TOrderPaymentMethod
  recipient: TOrderRecipientData
  shop: string
  status: EOrderStatus
  totalPrice: number
  updatedAt: Date
  user: string
  __v: number
  _id: string
}
